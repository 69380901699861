export const marketStatus = [
  {
    code: 'Open',
    icon: 'fas fa-circle',
    textColor: 'text-success',
    borderClass: 'success',
    text: 'เปิดรับแทง'
  },
  {
    code: 'Close',
    icon: 'fas fa-ban',
    textColor: 'text-secondary',
    borderClass: 'secondary',
    text: 'ปิดแทง'
  },
  {
    code: 'Delete',
    icon: 'fas fa-trash-alt',
    textColor: 'text-danger',
    borderClass: 'danger',
    text: 'ลบแล้ว'
  }
]

export const daysOfWeek = [
  {
    code: 'Mon',
    text: 'จันทร์'
  },
  {
    code: 'Tue',
    text: 'อังคาร'
  },
  {
    code: 'Wed',
    text: 'พุธ',
  },
  {
    code: 'Thu',
    text: 'พฤหัสบดี'
  },
  {
    code: 'Fri',
    text: 'ศุกร์'
  },
  {
    code: 'Sat',
    text: 'เสาร์'
  },
  {
    code: 'Sun',
    text: 'อาทิตย์'
  }
]

export const marketOpenBets = [
  {
    code: 'threeNumberTop',
    text: '3 ตัวบน'
  },
  {
    code: 'threeNumberTode',
    text: '3 ตัวโต๊ด'
  },
  {
    code: 'twoNumberTop',
    text: '2 ตัวบน'
  },
  {
    code: 'twoNumberBottom',
    text: '2 ตัวล่าง'
  },
  {
    code: 'runTop',
    text: 'วิ่งบน'
  },
  {
    code: 'runBottom',
    text: 'วิ่งล่าง'
  }
]
