import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import AppConfig from '@/configs/app.config.js'
import AuthService from '@/services/authService'
import SystemConfigService from '@/services/SystemConfigService'
import Auth from '@/helpers/auth'
import Swal from 'sweetalert2'

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  userProfile: {
    username: '',
    firstName: '',
    lastName: '',
    tel: '',
    lineId: '',
    status: '',
    createdAt: ''
  },
  account: {
    _id: null,
    balance: 0
  },
  marketGroups: [],
  marketPayrates: [],
  Merkets: [],
  /**
   * สำหรับแสดงข้อมูลที่ sidebar หน้าแทงหวย
   */
  round: {
    roundId: null,
    groupTitle: null,
    marketTitle: null,
    rate: null
  },
  rounds: null
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  updateProfile (state, profile) {
    state.userProfile = {
      ...state.userProfile,
      ...profile
    }
  },
  updateBalance (state, balance) {
    state.account.balance = balance
  },
  updateAccount (state, account) {
    state.account = {
      ...state.account,
      ...account
    }
  },
  updateMarketGroup(state, groups) {
    state.marketGroups = groups
  },
  updateMarketPayrate(state, rates) {
    state.marketPayrates = rates
  },
  updateMarkets(state, markets) {
    state.Merkets = markets
  },
  updateRound(state, round) {
    state.round = {
      ...state.round,
      ...round
    }
  },
  updateRounds(state, data) {
    state.rounds = data
  }
}

const actions = {
  reloadProfile({ commit }) {
    AuthService.getProfile()
    .then((response) => {
      console.log('profile', response)
      if(response.success) {
        commit('updateProfile', response.data)
      }
    })
    .catch((err)=>{
      console.log('profile-error', err)
    })
  },
  reloadBalance({ commit }) {
    AuthService.getBalance()
    .then((response) => {
      console.log('balance', response)
      if(response.success) {
        commit('updateBalance', response.data)
      }
    })
  },
  reloadAccount({ commit }) {
    AuthService.getAccount()
    .then((response) => {
      console.log('account', response)
      if(response.success) {
        commit('updateAccount', response.data)
      }else{
        throw new Error(response)
      }
    })
  },
  reloadMarketGroups({ commit }) {
    console.log('reloadMarketGroups!!!')
    SystemConfigService.getGroups()
    .then((response)=>{
      if(response.success) {
        commit('updateMarketGroup', response.data)
      }else{
        throw new Error(response?.data)
      }
    })
    .catch((err)=>{
      console.log(err.message)
    })
  },
  reloadMarkets({ commit }) {
    SystemConfigService.getMarkets().then((response)=>{
      console.log('Markets', response)
      if(response.success) {
        console.log(response)
        commit('updateMarkets', response.data)
      }
    })
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions
})
