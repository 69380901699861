import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import 'regenerator-runtime/runtime'
import moment from '@/helpers/moment'

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Validation
import { ValidationProvider } from 'vee-validate'
Vue.component('ValidationProvider', ValidationProvider)

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
Vue.component('loading', Loading)

import TicketStatus from '@/views/report/components/TicketStatus'
Vue.component('TicketStatus', TicketStatus)

import NumberStatus from '@/views/report/components/NumberStatus'
Vue.component('NumberStatus', NumberStatus)

import NumberType from '@/views/report/components/NumberType'
Vue.component('NumberType', NumberType)

// Notifications
import Notifications from 'vue-notification'
Vue.use(Notifications)

// Number format filter
Vue.filter('amountFormat', (num, dec=2, empty='-') => {
  if(!num)
    return empty
  return parseFloat(num).toFixed((dec||0)).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
})

Vue.filter('dispDateTime', (val, format="DD/MM/YYYY") => {
  if(val==='')
    return '-'
  return moment(val).format(format)
})

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
